@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: black;
  color: white;
}

#root {
  min-width: 320px;
}